.tabletMode {
   transition: all 0.65s ease 0s;
   width: 600px;
   height: 720px;
   margin: 0 auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   iframe {
      border: none;
      width: 100%;
      height: 100%;
   }
}

.phoneMode {
   transition: all 0.65s ease 0s;
   width: 375px;
   height: 100%;
   overflow: hidden;
   margin: 0 auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   iframe {
      border: none;
      width: 100%;
      height: 100%;
   }
}
.frame-content{
   overflow-y: scroll;
   max-height: 100vh;
   overflow-x: hidden;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
}

.desktopMode {
   width: 100%;
   transition: all 0.65s ease 0s;
}
