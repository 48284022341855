.modalContent {
   background-color: transparent;
   display: flex;
   width: 100%;
   height: 100%;

   .modalContent_pos_center {
      margin: 0 auto;
      border: 1px solid #E7E9E9;
      border-radius: 12px;
     // box-shadow: 0px 16px 32px rgba(69, 90, 100, 0.4);
   }
}
@media screen and (max-width: 1023px) {
   .mob-modalContentCenter {
      display: flex;
      align-items: center;
      justify-content: center;
      .modalContent_pos_center {
         margin: 0 16px;
      }
   }
}
