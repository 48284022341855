@font-face {
   font-family: 'Avenir Next';
   src: url('~assets/fonts/AvenirNextLTPro-Demi.otf');
   /* IE9 Compat Modes */
   src: url('~assets/fonts/AvenirNextLTPro-Demi.otf') format('embedded-opentype'),
      /* IE6-IE8 */
      url('~assets/fonts/AvenirNextLTPro-Demi.otf') format('woff2'),
      /* Super Modern Browsers */
      url('~assets/fonts/AvenirNextLTPro-Demi.otf') format('woff'),
      /* Pretty Modern Browsers */
      url('~assets/fonts/AvenirNextLTPro-Demi.otf') format('truetype'),
      /* Safari, Android, iOS */
      url('~assets/fonts/AvenirNextLTPro-Demi.otf') format('svg');
   /* Legacy iOS */
}

@font-face {
   font-family: 'Avenir Next regular';
   src: url('~assets/fonts/AvenirNextLTPro-Regular.otf');
}


.textInputRange {
   width: 100%;
   padding: 16px 0px;
   ::placeholder {
      color: #8a94a2;
   }
   .labelsRectangle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
   }

    .leftText__input {
      border: 1px solid #E7E9E9;
      padding: 5px;
      width: 48px;
      height: 24px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin-right: 12px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 168%;
      color: #131F1E;
    }
   .input__subLabel {
      margin: 5px 0;
   }

   label {
      width: 100%;
   }

   .textInput__input {
   //   border: 1px solid #cddaf1;
   //    box-sizing: border-box;
   //    border-radius: 4px;
   //    font-family: 'Avenir Next regular';
   //    width: 100%;
   //    line-height: 1.5;
   //    letter-spacing: 0.4px;
   //    font-size: 16px;
   //    background: #fbfdff;
   //    font-weight: bold;
   //    color: #3f4f65;
   //   user-select: none;
   }


   .textInput__input:disabled{
      cursor: not-allowed;
      color: rgb(214, 214, 214);
      border: 1px solid rgb(214, 214, 214);
   }

   .userLabel {
      display: flex;
      align-items: center;
      .userLabel_avatar {
         width: 24px;
         height: 24px;
         margin-bottom: 4px;
         img {
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
         }
      }
   }

   .textInput__wrapper {
      display: flex;
      margin-top: 12px;
      .leftText{
         display: flex;
         align-items: center;
      }

      .textInput__InputWrapper {
         position: relative;
         width: 100%;
         display: flex;


         .inputIcon {
            position: absolute;
            right: 24px;
            transform: translateY(-50%);
            top: 50%;
         }

      }

      .hintIcon {
         margin-left: 12px;
         display: flex;
         align-items: flex-end;

         svg {
            min-width: 24px;
            min-height: 24px;
            margin-bottom: 15px;
         }

         &:hover {
            cursor: pointer;
         }
      }
   }


   &__reset__button {
      padding: 2px 6px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #E7E9E9;
      margin-top: 16px;
      width: max-content;
      font-size: 12px;
   }

}



.form-label-group {
   position: relative;
   input::-webkit-input-placeholder {
      color: transparent;
   }

   input:-ms-input-placeholder {
      color: transparent;
   }

   input::-ms-input-placeholder {
      color: transparent;
   }

   input::-moz-placeholder {
      color: transparent;
   }

   input::placeholder {
      color: transparent;
   }
   input {
      background-color: #ffffff;
      padding: 19px 16px 21px 16px;
      font-size: 18px;
      border: 1px solid #949bbf;
      border-radius: 8px;
      color: #555555;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      font-family: 'Avenir Next regular';
      &:-webkit-autofill::first-line {
         font-size: 18px;
       }
       &:-moz-autofill::first-line {
         font-size: 18px;
       }
       &:-ms-autofill::first-line {
         font-size: 18px;
       }
   }
   label {
      position: absolute;
      top: 20px;
      left: 16px;
      display: block;
      width: 100%;
      margin-bottom: 0;
      line-height: 1.5;
      color: #555555;
      border: 1px solid transparent;
      border-radius: .25rem;
      transition: all .1s ease-in-out;
      font-family: 'Avenir Next regular';
      font-size: 18px;
      text-transform: capitalize;
   }
   input:not(:placeholder-shown) {
      padding-top: 29px;
      padding-bottom: 12px;
   }
   input:not(:placeholder-shown)~label {
      font-size: 12px;
      top: 0;
      left: 16px;
      color: #6c7aa8;
      padding-top: 7px;
      padding-bottom: 7px;
   }
   input:-webkit-autofill~label {
      font-size: 12px;
      top: 0;
      left: 16px;
      color: #6c7aa8;
      padding-top: 7px;
      padding-bottom: 7px;
   }
   input:-moz-autofill~label {
      font-size: 12px;
      top: 0;
      left: 16px;
      color: #6c7aa8;
      padding-top: 7px;
      padding-bottom: 7px;
   }
   input:-ms-autofill~label {
      font-size: 12px;
      top: 0;
      left: 16px;
      color: #6c7aa8;
      padding-top: 7px;
      padding-bottom: 7px;
   }
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus {
     transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
   }
   input:-moz-autofill,
   input:-moz-autofill:hover,
   input:-moz-autofill:focus {
     transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
   }
   input:-ms-autofill,
   input:-ms-autofill:hover,
   input:-ms-autofill:focus {
     transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
   }
   input:focus {
      outline: none;
   }
}



input[type=range] {
   -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
   width: 100%; /* Specific width is required for Firefox. */
   background:  #E7E9E9;; /* Otherwise white in Chrome */
   background-image: linear-gradient( #7BAEA9,#7BAEA9);
   border-radius: 2px;
   height: 2px;
   background-repeat: no-repeat;
   background-size: 0% 100%;
 }

 input[type=range]::-webkit-slider-thumb {
   -webkit-appearance: none;
 }

 input[type=range]:focus {
   outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
 }

 input[type=range]::-ms-track {
   width: 100%;
   cursor: pointer;

   /* Hides the slider so custom styles can be added */
   background: transparent;
   border-color: transparent;
   color: transparent;
 }


 /* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
   -webkit-appearance: none;
   cursor: pointer;
   margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
   background: #24554E;
    width: 20px;
    height: 20px;
    border-radius: 50%;

 }

 /* All the same stuff for Firefox */
 input[type=range]::-moz-range-thumb {
   cursor: pointer;
   background: #24554E;
    width: 20px;
    height: 20px;
    border-radius: 50%;

 }

 /* All the same stuff for IE */
 input[type=range]::-ms-thumb {
   cursor: pointer;
   background: #24554E;
    width: 20px;
    height: 20px;
    border-radius: 50%;

 }


//  input[type=range]::-webkit-slider-runnable-track {
//    width: 100%;
//    height: 2px;
//    cursor: pointer;
//    background: #E7E9E9;;
//    border-radius: 2px;
//  }

//  input[type=range]::-moz-range-track {
//    width: 100%;
//    height: 2px;
//    cursor: pointer;
//    background: #E7E9E9;;
//    border-radius: 2px;
//  }

//  input[type=range]::-ms-track {
//    width: 100%;
//    height: 2px;
//    cursor: pointer;
//    background: #E7E9E9;;
//    border-radius: 2px;
//  }
//  input[type=range]::-ms-fill-lower {
//    width: 100%;
//    height: 2px;
//    cursor: pointer;
//    background: #E7E9E9;;
//    border-radius: 2px;
//  }
//  input[type=range]:focus::-ms-fill-lower {
//    background: #3071a9;
//  }
//  input[type=range]::-ms-fill-upper {
//    width: 100%;
//    height: 2px;
//    cursor: pointer;
//    background: #E7E9E9;;
//    border-radius: 2px;
//  }
