:root {
   --base: #22272F;
   --primary_90: #153833;
   --primary_80: #24554E;
   --primary_50: #36796F;
   --primary_40: #54938B;
   --primary_20: #7BAEA9;
   --primary_10: #A6C9C5;
   --primary_5: #E8F2F1;
   --greyscale_90: #131F1E;
   --greyscale_60: #444C4B;
   --greyscale_40: #727978;
   --greyscale_30: #A1A5A5;
   --greyscale_20: #D0D2D2;
   --greyscale_10: #E7E9E9;
   --greyscale_5: #F0F2F2;
   --greyscale_0: #FFFFFF;
   --blackscale_80: rgba(19, 31, 30, 0.8);
   --blackscale_70: rgba(19, 31, 30, 0.7);
   --blackscale_60: rgba(19, 31, 30, 0.6);
   --blackscale_40: rgba(19, 31, 30, 0.4);
   --blackscale_20: rgba(19, 31, 30, 0.2);
   --blackscale_10: rgba(19, 31, 30, 0.1);
   --blackscale_5: rgba(19, 31, 30, 0.05);
   --light: #EDF0EF;
   --dark_danger: #A61C23;
   --mid_dange: #F8C5C5;
   --danger: #D12D36;
   --light_danger: #FFF1F1;
   --success: #379552;
   --light_success: #DEFBE6;
   --alarm: #D3AC1E;
   --light_alarm: #FFF8E1;
   --link_active: #3060BD;
   --link_visited: #8830BD;
   --scale_90: #131F1E;
   --pink_1:#FEE1F2;
   --additional_error:#D12D36;
   --additional_success:#379552;
   --additional_danger:#D3AC1E;
}
