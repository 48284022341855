.page__title {
   display: flex;
   align-items: center;
   gap: 12px;
   .tooltip {
      display: flex;
   }
   .__react_component_tooltip {
      max-width: 310px;
   }
}
