.affiliate__checkout__header{
   background-color: white;
   width: 100%;
   padding: 18px 24px;
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #E7E9E9;
   &__left{
      display: flex;
      align-items: center;
      gap: 8px;
      &__icon{
         cursor: pointer;
         display: flex;
         align-items: center;
      }
   }
   &__mods{
      display: flex;
      align-items: center;
      gap: 8px;
   }
   &__mod{
      padding: 4px;
      display: flex;
      cursor: pointer;
      border-radius: 8px;
      align-items: center;
   }
   &__mod__active{
      border: 1px solid #24554E;
      path{
         fill: #24554E;
      }
   }
   &__actions{
      display: flex;
      align-items: center;
      gap: 12px;
      .undoredo{
         display: flex;
         align-items: center;
         &__button{
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 4px;
         }
      }
   }
   .liner{
      margin-right: 8px;
      width: 1px;
      background-color: #D9D9D9;
      height: 24px;
   }
}
