.leftBar {
  // height: 100vh;
  height: 100%;
}

@media screen and (max-width: 1280px) {
   .leftBar {
      display: block;
   }

}
@media screen and (max-width: 1023px) {
   .leftBar {
      display: none;
   }
}

