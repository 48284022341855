
.mobileHeader {
   display: none;
}

@media screen and (max-width: 1023px) {
   .mobileHeader {
   display: flex;
   }
}

