.SelectItem {
   position: relative;
   display: inline-block;
   display: flex;
   width: 100%;
   flex-direction: column;
   min-width: max-content;
   gap: 8px;
  .selectItem__label {
      display: flex;
      justify-content: space-between;
   }
   &__options {
      overflow-y: auto;
      // background-color: white;
      width: 100%;
      top: 100%;
      // border-radius: 12px;
      // z-index: 1000;
      // border: 1px solid #e7e9e9;
      max-height: 300px;
      scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
      scrollbar-width: thin;
      box-sizing: border-box;

      &__wrapper {
         border: 1px solid #e7e9e9;
         box-sizing: border-box;
         background-color: white;
         border-radius: 12px;
         z-index: 1000;
         width: 100%;


      .inputSearch {
         padding: 12px;

         .inputNew {
            min-height: 36px;
         }
      }

      }

      &::-webkit-scrollbar-track {
         border-radius: 10px;
         margin: 5px 0px;
         background-color: transparent;
      }

      &::-webkit-scrollbar {
         width: 3px;
         height: 2px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         border-radius: 3px;
         box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         background-color: rgb(226, 226, 226);
      }
   }
   &__item {
      padding: 2.5px 5px 2.5px 10px;
      cursor: pointer;
      max-width: 100%;
      &:first-child {
         padding-top: 5px;
      }
      &:last-child {
         padding-bottom: 5px;
      }
   }
   &__button__large {
      background: #ffffff;
      border: 1px solid #e7e9e9;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      min-height: 50px;
      justify-content: space-between;
      cursor: pointer;
      &__placeholder {
         color: #727978 !important;
      }
   }
   &__button__medium {
      background: #ffffff;
      border: 1px solid #e7e9e9;
      border-radius: 12px;
      padding: 9px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      svg {
         path {
            fill: {
               background: #131f1e;
            }
         }
      }
      &__placeholder {
         color: #727978 !important;
      }
   }
   &__button {
      background: #ffffff;
      border: 1px solid #e7e9e9;
      border-radius: 12px;
      padding: 8px 8px 8px 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .SelectItem__button__icon {
         width: 17.67px;
         height: 17.67px;
         path {
            color: #727978;
         }
      }
      svg {
      }
      &__placeholder {
         color: #727978 !important;
      }
   }
   &__active {
      svg:not(.disableIconAnimation) {
         transform: rotate(180deg);
      }
   }
   &__disabled {
      background: #f8fafa;
   }
}
.SelectItem__button__transactions {
   padding: 12px 16px;
   background: #ffffff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   min-width: 223px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
   &__placeholder {
      color: #727978 !important;
   }
}


// .MuiPaper-root {
//    background-color: inherit !important;
// }


.SelectItem__button__icon__container {
   display: flex;
   align-items: center;
   gap: 4px;
   &__remove {
      border: 1px solid grey;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0 2px;
      svg {
         width: 16px;
      }
   }
}