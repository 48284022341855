.adminLayout {
   display: flex;
   width: 100%;
   height: 100vh;
   // min-width: 1024px;
   overflow: hidden;
   
   @media (max-width: 1024px) {
      overflow: auto;
      max-height: -webkit-fill-available;
   }
}

.icon__text__wrapper {
   display: flex;
   align-items: flex-end;
   justify-content: center;
   gap: 5px;

   svg {
      margin-bottom: 2px;
   }

   .link__text {
      margin-top: 10px;
      cursor: pointer;
      text-align: center;
      color: #24554e;
   }
}