.affiliate__section__top{
    display: flex;
    flex-direction: column;
    padding: 44px;
    border-radius: 24px;
    align-items: center;
    min-width: 487px;
    transition: 0.3s all;
    border: 1px;
    max-width: 487px;
}
.mark{
    // border: 1px dashed #24554E !important;
    position: relative;
    &::before{
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        // content: 'Edit';
        // color: white;
        // font-size: 12px;
        // font-weight: 400;
        // cursor: pointer;
        // line-height: 148%;
        // z-index: 88;
        // border-radius: 8px;
        // background-color: #24554E;
        // padding: 2px 4px;
    }
}