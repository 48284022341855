.backdrop {
   width: 100%;
   height: 100%;
   position: relative;

}

#modals-root {
   &.active {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
}

.modal-content-body {
   max-height: 60vh;
   overflow-y: auto;
}
.shadow-bottom{
   box-shadow: 0px -4px 12px rgba(97, 123, 136, 0.24)
}
.shadow-center{
   box-shadow: 0px 16px 32px rgba(69, 90, 100, 0.4)
}
