.affiliate-drag {
   .handle {
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 5px;
      text-align: center;
      background-color: lightblue;
      color: blue;
      border-radius: 5px;
      margin-right: 8px;
      position: absolute;
      top: 0px;
      left: calc(50% - 10px);
   }

   .draggable-element .darg-handle {
      margin-top: 0;
      background: #673ab7;
      width: 29px;
      height: 20px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .drop_element_here {
      position: relative;
      width: 100%;
      top: 50px;
      line-height: 1.3;
      font-size: 20px;
      font-family: "Avenir Next";
      background: #f1f1f1;
      color: #c3c3c3;
      width: 100%;
      border: 1px dotted #7cb740;
      border-radius: 4px;
      padding: 10px;
      display: none;
      text-align: center;
      box-sizing: border-box;
   }

   section.drop_element_here:first-of-type {
      display: block;
   }

   .drop_element_here_abs {
      position: absolute;
   }

   .component__delete {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 99999999;
      margin: 0;
   }

   .component__delete {
      background: #fff;
      font-size: 18px;
      padding: 4px 4px 1px 4px;
      box-shadow: 0 1px 5px #7b7b7b;
      border-radius: 4px;
      cursor: pointer;
   }

   .component__delete {
      svg path {
         fill: rgba(0, 0, 0, 0.4);
      }
   }

   .component__delete {
      &:hover {
         svg path {
            fill: rgba(0, 0, 0, 0.6);
         }
      }
   }
}

.draggable_component {
   position: relative;
}

.draggable_component:hover .component__delete {
   display: block;
}
