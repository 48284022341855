.colendar{
    border: 1px solid #E7E9E9;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 12px rgba(19, 31, 30, 0.2));
    border-radius: 12px;
    width: 312px;
   max-width: 350px;
    background-color: #fff;
    padding: 20px;
}
