.calendar_modal{
    width: 100%;
    text-align: center;
    &_done{
        cursor: pointer;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 132%;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
        padding: 8px 12px;
        background: #24554E;
        border-radius: 12px;
        border: none;
    }
    .calendar_inner{
        display: flex;
        justify-content: space-between;
        .calendar_inner_months, .calendar_inner_year{
            height:190px;
            width: 47%;
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #FF0000;
            }
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .calendar_month,.calendar_year{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 148%;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #727978;
            padding: 12px 0px;
            margin-bottom: 20px;
            cursor: pointer;

        }
        .calendar_selected_month,.calendar_selected_year{
            width: 100%;
            text-align: center;
            background: #E8F2F1;
            border-radius: 8px;
            color: #131F1E;
        }
    }
}
