.affiliate__view__button{
    max-height: 44px;
    min-height: 44px;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    border-radius: 12px;
    transition: 0.3s;
    font-family: "Roboto";
}

.affiliate__view__button__editor{
    background-color: inherit;
    border: 0px;
    min-width: min-content;    
    outline: none;
}