.modal__new {
   position: absolute;
   &__background {
      background: rgba(19, 31, 30, 0.4);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
      height: 100vh;
   }
   &__content {
      position: fixed;
      top: 50%;
      left: 50%;
      background: #ffffff;
      border-radius: 12px;
      padding: 20px;
      max-height: 97vh;
      max-height: 97dvh;
      z-index: 99999;
      overflow-y: auto;
      transform: translate(-50%, -50%);
      min-width: 400px;
      max-width: 100%;
      scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
      scrollbar-width: thin;


      &::-webkit-scrollbar-track {
         border-radius: 10px;
         background-color: transparent;
      }

      &::-webkit-scrollbar {
         width: 5px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         border-radius: 3px;
         box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         background-color: rgb(226, 226, 226);
      }
      @media screen and (max-width: 1023px) {
         min-width: auto;
         max-width: 400px;
         width: 100%;
         padding: 16px;
         .design__course__drip__date{
            max-width: 100%;
         .design__course__drip__date__content {
            max-width: 100%;
            .design__course__drip__date__buttons {
               flex-direction: column;
            }
         }
         .design__course__drip__date__buttons {
            flex-direction: column;
         }
      }
      }
   }
}


.communitySignupModal {
   padding: 0;
   overflow: hidden;
   >div {
      height: 100% !important;
   }
   .signin__template {
      height: 100%;
   }
   .signin__template__content {
      max-height: 100%;
      width: 100%;
      .checkbox__wrapper .texts__wrapper {
         flex-wrap: wrap;
      }
      .sign__template__bottom__contacts {
         padding-bottom: 16px;
      }
   }
}