.affiliate__select{
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__item{
        display: flex;
        border-radius: 8px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 8px 12px;
        &__arrow{
            display: flex;
            align-items: center;
        }
    }
}