.logout {
   position: relative;

   &__link{
      padding: 16px 16px 16px 32px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      border-top: 1px solid #E7E9E9;
      gap: 12px;
   }

   @media screen and (max-width: 1024px) {
      .sidebarGroup__user {
         & > div:nth-child(2) {
            display: none;
         }
         & > div:first-child {
            & > span {
               width: 72vw;
            }
            & > svg {
               display: none;
            }
         }
      }
   }
}

@media screen and (max-width: 1280px) {
   .logout {
      .logoutPopup {
         width: 158px;
      }
   }
}
.logoutPopup {
   z-index: 9999;
   background: #ffffff;
   border: 1px solid rgba(19, 31, 30, 0.1);
   box-shadow: 0px 4px 10px rgba(19, 31, 30, 0.1),
      1px 2px 2px rgba(19, 31, 30, 0.04);
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   padding: 4px 0px;
   > div {
      &:hover {
         background-color: #E8F2F1;
         cursor: pointer;
      }
      display: flex;
      align-items: center;
      padding: 13px 23px 13px 24px;
      span{
         white-space: nowrap;
      }
      a {
         display: flex;
         align-items: center;
         text-decoration: none;
      }
      .accountIcon {
         margin-right: 12px;
      }
      .logoutIcon {
         margin-right: 12px;
      }
   }
   .logoutPopup__account {
      // margin-bottom: 26px;
   }
}