.popup__wrapper {
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .popup__inner__wrapper {
        max-width: 560px;
        width: 100%;
        border-radius: 12px;
        padding: 40px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        svg {
            cursor: pointer;
        }

        .text__icon__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                margin-bottom: 32px;
                cursor: default;
            }

            .title__wrapper {
                margin-bottom: 4px;
            }

            .subtitle__wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    line-height: 1.7;
                    text-align: center;
                    font-weight: 400;
                }
            } 
        }


        .buttons__wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 32px;

            button {
                min-height: 44px !important;
            }
        }
    }
}

.bottom__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}