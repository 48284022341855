.upload__modal {
   position: fixed;
   left: 0;
   top: 0;
   display: flex;
   align-items: center;
   z-index: 8000;
   justify-content: center;
   width: 100%;
   height: 100vh;
   &__background {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 1px;
      bottom: 0px;
      width: 100%;
      height: 100vh;
      /* black scale/40 */
      z-index: 9998;
      background: rgba(19, 31, 30, 0.4);
   }
   &__content {
      &:has(.cropArea) {
         width: clamp(50vw, 618px, 90vw);
      }
      z-index: 9999;
      position: relative;
   }
   .upload__view {
      min-width: min(400px, 90vw);
   }
}
