.selectedWrapper_container {
    padding: 24px;
   .selectedWrapper {
      display: flex;
      background-color: #fbfdff;
   }

   .selected {
      cursor: pointer;
   }

   .shadow {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
   }

   .active {
      position: relative;
      box-shadow: inset 0 0 0 1px #7cb740;
      border: 1px solid #7cb740;
      box-sizing: border-box;
   }

}
