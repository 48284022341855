.quill {

   .ql-picker.ql-font .ql-picker-label[data-value="Inter"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Inter"]::before {
      font-family: Inter, sans-serif;
      content: "Inter";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
      font-family: Roboto, sans-serif;
      content: "Roboto";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Lobster"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Lobster"]::before {
      font-family: Lobster, cursive;
      content: "Lobster";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Abril Fatface"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Abril Fatface"]::before {
      font-family: Abril Fatface, cursive;
      content: "Abril Fatface";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="DM Serif Display"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="DM Serif Display"]::before {
      font-family: DM Serif Display, sans-serif;
      content: "DM Serif Display";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Anton"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Anton"]::before {
      font-family: Anton, sans-serif;
      content: "Anton";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
      font-family: Lato, sans-serif;
      content: "Lato";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Poppins"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Poppins"]::before {
      font-family: Poppins, sans-serif;
      content: "Poppins";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Parisienne"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Parisienne"]::before {
      font-family: Parisienne, cursive;
      content: "Parisienne";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Shrikhand"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Shrikhand"]::before {
      font-family: Shrikhand, cursive;
      content: "Shrikhand";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Playfair Display"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Playfair Display"]::before {
      font-family: Playfair Display, cursive;
      content: "Playfair Display";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Roboto Mono"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Roboto Mono"]::before {
      font-family: Roboto Mono, monospace;
      content: "Roboto Mono";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Lobster Two"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Lobster Two"]::before {
      font-family: Lobster Two, cursive;
      content: "Lobster Two";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
      font-family: Montserrat, sans-serif;
      content: "Montserrat";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Oswald"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Oswald"]::before {
      font-family: Oswald, sans-serif;
      content: "Oswald";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Andale Mono"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Andale Mono"]::before {
      font-family: andale mono, times;
      content: "Andale Mono";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
      font-family: arial, helvetica, sans-serif;
      content: "Arial";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Brush Script MT"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Brush Script MT"]::before {
      font-family: Brush Script MT, cursive;
      content: "Brush Script MT";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Arial Black"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Arial Black"]::before {
      font-family: arial black, avant garde;
      content: "Arial Black";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Garamond"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Garamond"]::before {
      font-family: Garamond, serif;
      content: "Garamond";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Book Antiqua"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Book Antiqua"]::before {
      font-family: book antiqua, palatino;
      content: "Book Antiqua";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Comic Sans MS"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Comic Sans MS"]::before {
      font-family: comic sans ms, sans-serif;
      content: "Comic Sans MS";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Courier New"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Courier New"]::before {
      font-family: courier new, courier;
      content: "Courier New";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before {
      font-family: georgia, palatino;
      content: "Georgia";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before {
      font-family: helvetica;
      content: "Helvetica";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Impact"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Impact"]::before {
      font-family: impact, chicago;
      content: "Impact";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Symbol"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Symbol"]::before {
      font-family: symbol;
      content: "Symbol";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before {
      font-family: tahoma, arial, helvetica, sans-serif;
      content: "Tahoma";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Terminal"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Terminal"]::before {
      font-family: terminal, monaco;
      content: "Terminal";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Times New Roman"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Times New Roman"]::before {
      font-family: times new roman, times;
      content: "Times New Roman";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Trebuchet MS"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Trebuchet MS"]::before {
      font-family: trebuchet ms, geneva;
      content: "Trebuchet MS";
   }

   .ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
      font-family: verdana, geneva;
      content: "Verdana";
   }

   // .ql-picker.ql-font .ql-picker-label[data-value="Webdings"]::before,
   // .ql-picker.ql-font .ql-picker-item[data-value="Webdings"]::before {
   //   font-family: webdings;
   //   content: "Webdings";
   // }

   // .ql-picker.ql-font .ql-picker-label[data-value="Wingdings"]::before,
   // .ql-picker.ql-font .ql-picker-item[data-value="Wingdings"]::before {
   //   font-family: wingdings, zapf dingbats;
   //   content: "Wingdings";
   // }

   .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
   .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
      font-family: Raleway, sans-serif;
      content: "Raleway";
   }
}

.ql-font-Inter {
   font-family: Inter, sans-serif;
}

.ql-font-Roboto {
   font-family: Roboto, sans-serif;
}

.ql-font-Lobster {
   font-family: Lobster, cursive;
}

.ql-font-Abril Fatface {
   font-family: Abril Fatface, cursive;
}

.ql-font-DM Serif Display {
   font-family: DM Serif Display, sans-serif;
}

.ql-font-Anton {
   font-family: Anton, sans-serif;
}

.ql-font-Lato {
   font-family: Lato, sans-serif;
}

.ql-font-Poppins {
   font-family: Poppins, sans-serif;
}

.ql-font-Parisienne {
   font-family: Parisienne, cursive;
}

.ql-font-Shrikhand {
   font-family: Shrikhand, cursive;
}

.ql-font-Playfair Display {
   font-family: Playfair Display, cursive;
}

.ql-font-Roboto Mono {
   font-family: Roboto Mono, monospace;
}

.ql-font-Lobster Two {
   font-family: Lobster Two, cursive;
}

.ql-font-Montserrat {
   font-family: Montserrat, sans-serif;
}

.ql-font-Oswald {
   font-family: Oswald, sans-serif;
}

.ql-font-Andale Mono {
   font-family: andale mono, times;
}

.ql-font-Arial {
   font-family: arial, helvetica, sans-serif;
}

.ql-font-Brush Script MT {
   font-family: Brush Script MT, cursive;
}

.ql-font-Arial Black {
   font-family: arial black, avant garde;
}

.ql-font-Garamond {
   font-family: Garamond, serif;
}

.ql-font-Book Antiqua {
   font-family: book antiqua, palatino;
}

.ql-font-Comic Sans MS {
   font-family: comic sans ms, sans-serif;
}

.ql-font-Courier New {
   font-family: courier new, courier;
}

.ql-font-Georgia {
   font-family: georgia, palatino;
}

.ql-font-Helvetica {
   font-family: helvetica;
}

.ql-font-Impact {
   font-family: impact, chicago;
}

.ql-font-Symbol {
   font-family: symbol;
}

.ql-font-Tahoma {
   font-family: tahoma, arial, helvetica, sans-serif;
}

.ql-font-Terminal {
   font-family: terminal, monaco;
}

.ql-font-Times New Roman {
   font-family: times new roman, times;
}

.ql-font-Trebuchet MS {
   font-family: trebuchet ms, geneva;
}

.ql-font-Verdana {
   font-family: verdana, geneva;
}

.ql-font-Webdings {
   font-family: webdings;
}

.ql-font-Wingdings {
   font-family: wingdings, zapf dingbats;
}

.ql-font-Raleway {
   font-family: Raleway, sans-serif;
}
