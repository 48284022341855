.modal__new__content.onboarding_wrapper{
    padding: 0;
    overflow: hidden;
}

.onboarding_wrapper {
    width: 80%;
    max-width: 1020px;
    display: flex;
    flex-direction: column;

    .modal_title_wrapper {
        width: 100%;
        background-color: #F8FAFA;
        padding: 24px 40px;

        .onboarding_logo {
            width: 256px;
            height: 58px;
            display: flex;
            justify-content: center;
            margin: 24px auto 0;
        }

        .slider_wrapper {
            width: 100%;
            height: 8px;
            background-color: #F0F2F2;
            margin-top: 41px;
            border-radius: 8px;
            overflow: hidden;

            .slider {
                height: 100%;
                background: linear-gradient(90deg, #36796F 0%, #7CAE7B 100%);
            }
        }
    }

    .onboarding_content {
        width: 100%;
        padding: 32px 40px;
        display: flex;
        flex-direction: column;

        .answers_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 24px;

            .answer_item {
                width: 100%;
                min-width: 250px;
                max-width: 290px;
                padding: 16px 0;
                display: flex;
                flex-direction: column;
                gap: 16px;
                align-items: center;
                border-radius: 12px;
                border-width: 1px;
                border-style: solid;
                cursor: pointer;

                .answer_icon_wrapper {
                    width: 52px;
                    height: 52px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .input_icon_wrapper {
            width: 100%;
            height: 84px;
            display: flex;
            align-items: center;
            border-radius: 12px;
            padding-left: 22px;
        }

        .input__default__label {
            font-weight: 400;
            margin-top: 24px;
        }
    }

    .button_wrapper {
        padding: 0 40px 24px;
        display: flex;
        justify-content: flex-end;
    }

    .VideoItem {
        margin: 0;
    }

    .embed-container {
        width: 100%;
        aspect-ratio: 16 / 9;
        & > iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}


@media screen and (max-width: 1024px) {
    .onboarding_wrapper {
        width: 91%;
        .modal_title_wrapper {
            padding: 24px;

            .onboarding_logo {
                width: 191px;
                height: 44px;
                margin: 0 auto;
            }

            .slider_wrapper {
                margin-top: 20px;
            }
        }

        .onboarding_content {
            padding: 32px 20px;

            & > .textBasic_size_medium {
                font-size: 14px;
            }

            .answers_wrapper {
                gap: 12px;
            }

            .input_icon_wrapper {
                display: none;
            }

            & > .textBasic_size_size_28 {
                margin-top: 0 !important;
            }
        }

        .button_wrapper {
            padding: 4px 20px 20px;

            & > button {
                width: 100%;
                height: 44px;
            }
        }
    }
}

@media screen and (max-width: 801px) {
    .onboarding_wrapper {
        max-height: 80%;
        .onboarding_content {
            overflow-y: auto;
            .answers_wrapper {

                .answer_item {
                    min-width: none;
                    max-width: none;
                    width: 100%;
                }
            }
        }
    }
}
