.adminContent:not(.adminContentAlt) {
   padding: 0 33px 0;
   max-width: 1440px;
   margin: 0px auto;
   background: #F4F7F7;
   width: 100%;
   padding-top: 2px;
   box-sizing: border-box;
   display: flex;
  // overflow-y: auto;
   flex-direction: column;
   // >div {
   //    height: max-content;
   //    min-height: 100%;
   // }

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   @media screen and (max-width: 1023px) {
      padding: 0 16px;
   }
}
