.btnBasic {
   font-family: "Roboto";
   font-weight: 500;
   font-size: 16px;
   border-radius: 12px;
   border: none;
   letter-spacing: 1%;
   text-align: center;
   width: auto;
   // min-width: 102px;
   cursor: pointer;
   user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   color: var(--greyscale_0);
   padding: 13px 16px;
   gap: 8px;
   white-space: nowrap;

   &:disabled {
      opacity: 0.6;
      cursor: auto;
   }

   .mobTextRemove {
      @media screen and (max-width: 1023px) {
        display: none;
      }
   }
   div{
      display: flex;
      align-items: center;
      justify-content: center;
   }
   // svg {
   //    path {
   //       fill: var(--greyscale_0);
   //    }
   // }
   &_theme {
      &_privacy {
         background: #f8fafa;
         min-height: 48px !important;
         border-radius: 12px;
         color: #24554e;
     }

     &_new_privacy {
         background: #f8fafa;
         min-height: 48px !important;
         border-radius: 12px;
         color: #24554e;
         border: 1px solid #24554E;
      }


      &_change {
         background: rgba(19, 31, 30, 0.4);
         border: 1px solid #ffffff;
      }
      &_grey {
         background: #27282B;
         border: 1px solid rgba(255, 255, 255, 0.10);
         svg {
            path {
               fill: var(--greyscale_0);
            }
         }
      }
      &_grey_white {
         background: #E7E9E9;
         border: 1px solid #E7E9E9;
         svg {
            path {
               fill: var(--greyscale_0);
            }
         }
         color: #131F1E;
      }
      &_explore{
         background: var(--buttonBgcolor);
      }
      &_school{
         background: rgba(19, 31, 30, 0.6);
      }
      &_other{
         background-color: inherit;
      }
      &_canva {
         background: #20c4cb;
         width: 100%;
         transition: 0.3s ease;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         padding: 7px 16px 9px;
         gap: 4px;
         font-weight: 400;
         letter-spacing: 0.01em;
         font-size: 14px;
         line-height: 132%;
      }
      &_primary {
         background-color: var(--primary_80);
         border: 1px solid;
         transition: 0.3s ease;
         // &:hover {
         //    background-color: var(--primary_50);
         // }
         &:active {
            background-color: var(--primary_90);
         }
         &_disabled {
            &:hover {
               background-color: var(--primary_80);
               color: var(--greyscale_0);
            }
            &:active {
               background-color: var(--primary_80);
               color: var(--greyscale_0);
            }
            opacity: 0.4;
            svg {
               path {
                  fill: var(--greyscale_0);
               }
            }
         }
      }
      &_purple {
         background-color: rgba(123, 83, 233, 1);
         border: 1px solid rgba(123, 83, 233, 1);
         transition: 0.3s ease;
         &:hover {
            background-color: #6434e7;
         }
         &:active {
            background-color: #6434e7;
         }
         &_disabled {
            &:hover {
               background-color: var(--blackscale_20);
               color: var(--blackscale_40);
            }
            &:active {
               background-color: var(--blackscale_20);
               color: var(--blackscale_40);
            }
            opacity: 0.4;
            svg {
               path {
                  fill: var(--blackscale_40);
               }
            }
         }
      }
      &_lightPurple {
         color: rgba(123, 83, 233, 1);
         border: 1px solid rgba(123, 83, 233, 1);
         background-color: inherit;
         transition: 0.3s ease;
         svg {
            path {
               fill: rgba(123, 83, 233, 1);
            }
         }
         &:hover {
            background-color: var(--blackscale_10);
         }
         &:active {
            background-color: var(--blackscale_20);
         }
         &_disabled {
            &:hover {
               background-color: var(--blackscale_5);
               border-color: var(--blackscale_20);
            }
            &:active {
               background-color: var(--blackscale_5);
               border-color: var(--blackscale_20);
            }
            background-color: var(--blackscale_5);
            border-color: var(--blackscale_20);
            color: var(--blackscale_20);
            svg {
               path {
                  fill: var(--blackscale_20);
               }
            }
         }
      }
      &_more {
         border: 1px solid #24554e;
         border-radius: 12px;
         background-color: inherit;
         color: #24554e;
         width: 69px;
         height: 32px;
         font-size: 12px;
         padding: 0 !important;
         box-sizing: border-box;
         min-width: 69px;
      }
      &_error{
         border: 1px solid #D12D36;
         border-radius: 12px;
         background-color: inherit;
         color: #D12D36;
         height: 32px;
         font-size: 12px;
         box-sizing: border-box;
         min-width: 136px;
         width: 136px;
      }
      &_filter_true {
         background: #e7e9e9;
      }
      &_secondary {
         color: var(--primary_80);
         border: 1px solid var(--primary_80);
         background-color: inherit;
         transition: 0.3s ease;
         // svg {
         //    path {
         //       fill: var(--primary_80);
         //    }
         // }
         // &:hover {
         //    background-color: var(--primary_5);
         // }
         // &:active {
         //    background-color: var(--primary_10);
         // }
         &_disabled {
            // &:hover {
            //    background-color: var(--blackscale_5);
            //    border-color: var(--blackscale_20);
            // }
            // &:active {
            //    background-color: var(--blackscale_5);
            //    border-color: var(--blackscale_20);
            // }
            // background-color: var(--blackscale_5);
            // border-color: var(--blackscale_20);
            // color: var(--blackscale_20);
            opacity: 0.4;
            svg {
               path {
                  fill: var(--blackscale_20);
               }
            }
         }
      }
      &_white {
         color: #fff;
         border: 1px solid #fff;
         background-color: inherit;
         transition: 0.3s ease;
         svg {
            path {
               fill: #fff;
            }
         }
         &:hover {
            background-color: inherit;
         }
         &:active {
            background-color: inherit
         }
         &_disabled {
            opacity: 0.4;
            svg {
               path {
                  fill: #fff;
               }
            }
         }
      }
      &_red {
         background: #d12d36;
         border-radius: 12px;
         transition: 0.3s ease;
         color: #fff;
         svg {
            path {
               fill: #fff;
            }
         }
      }
      &_tertiary {
         background-color: inherit;
         border: none;
         color: var(--greyscale_90);
         svg {
            path {
               fill: var(--greyscale_90);
            }
         }
         &:hover {
            color: var(--greyscale_40);
            svg {
               path {
                  fill: var(--greyscale_40);
               }
            }
         }
         &:active {
            color: var(--greyscale_60);
         }
      }
      &_tertiaryGreen {
         background-color: inherit;
         border: none;
         color: var(--primary_80);
         svg {
            path {
               fill: var(--primary_80);
            }
         }
         &:hover {
           // color: var(--greyscale_40);
            background-color: #e8f2f1;
            svg {
               path {
                  fill: var(--greyscale_40);
               }
            }
         }
         &:active {
            color: var(--greyscale_60);
         }
         &_disabled {
            &:hover {
               background-color: var(--primary_80);
               color: var(--greyscale_0);
            }
            &:active {
               background-color: var(--primary_80);
               color: var(--greyscale_0);
            }
            opacity: 0.4;
            svg {
               path {
                  fill: var(--greyscale_0);
               }
            }
         }
      }

      &_blue {
         background-color:  #3060BD;
         border: 1px solid #3060BD;
         transition: 0.3s ease;
         &:hover {
            background-color: #3060BD;
         }
         &:active {
            background-color: #3060BD;
         }
         &_disabled {
            &:hover {
               background-color: var(--blackscale_20);
               color: var(--blackscale_40);
            }
            &:active {
               background-color: var(--blackscale_20);
               color: var(--blackscale_40);
            }
            opacity: 0.4;
            svg {
               path {
                  fill: var(--blackscale_40);
               }
            }
         }
      }

   }
   &_size {
      &_small {
         padding: 8px 12px;
      }
      &_new_small {
         font-size: 14px;
         font-weight: 400;
      }

      &_new_small_weight {
         font-size: 14px;
         font-weight: 500;
      }
      &_new_small_weight44 {
         height: 44px;
         font-size: 14px;
         font-weight: 500;
      }

      &_small14 {
         padding: 0 4px;
         font-size: 14px;
      }
      &_medium44 {
         height: 44px;
      }
      &_medium {
         padding: 11px 16px;
         @media screen and (max-width: 1023px) {
            max-height: 36px;
            box-sizing: border-box;
            span {
               font-size: 12px;
            }
         }
      }
      &_large {
         min-height: 44px;
         padding: 12px 16px;
         max-height: 44px;
      }
      &_largeFull {
         min-height: 44px;
         padding: 12px 16px;
         max-height: 44px;
         width: 196px;
         @media screen and (max-width: 1023px) {
            width: 100%;
         }
      }
      &_xsmall {
         padding: 10px 12px;
         font-size: 12px;
         line-height: 132%;
         max-height: 36px;
      }
      &_large120 {
         min-height: 44px;
         padding: 12px 16px;
         max-height: 44px;
         min-width: 120px;
      }
      &_small63 {
         padding: 13px 16px;
         width: fit-content;
         max-height: 44px;
      }
      &_large50 {
         padding: 13px 16px;
      }
      &_large56 {
         padding: 11px 16px;
         width: 186px;
      }
      &_largeDefault {
         padding: 13px 16px;
         min-width: 120px;
      }
      &_xLarge {
       font-size: 16px;
       font-weight: 700;
       line-height: 32px;
      }
   }
   &_theme_filter {
      background: #ffffff;
      display: flex;
      align-items: center;
      padding: 8px 13px;
      font-weight: 400;
      font-size: 12px;
      line-height: 132%;
      gap: 8px;
      min-height: 36px !important;
      color: #131f1e;
      min-width: min-content;
      svg {
         path {
            fill: #131f1e;
         }
      }
      border: 1px solid #e7e9e9;
      border-radius: 12px;
   }
}

.btnBasic_size_full {
   width: 100%;
   height: 44px;
   padding-right: 20px;
   padding-left: 20px;
}

@media screen and (max-width: 1023px) {
.btnBasic_size_mob {
   width: 36px;
   height: 36px;
}
}
// .btnBasic_theme_lightGreen {
//    background-color: rgba(124, 183, 64, 0.1);
//    color: #7cb740;
// }

// .btnBasic_theme_darkGreen {
//    background-color: #7cb740;
//    color: #ffffff;
// }

// .btnBasic_theme_darkRed{
//    background-color: #d5624e;
//    color: #ffffff;
// }

// .btnBasic_theme_grey {
//    background-color: #f0f4f7;
//    color: #3f4f65;
// }

// .btnBasic_theme_blueBordered {
//    background-color: transparent;
//    color: #006dff;
//    border: 2px solid #006dff;
// }

// .btnBasic_theme_greenBordered {
//    background-color: transparent;
//    color: #7cb740;
//    border: 2px solid #7cb740;
// }

// .btnBasic_theme_redBordered {
//    background-color: transparent;
//    color: #d5624e;
//    border: 2px solid #d5624e;
// }

// .btnBasic_theme_lightBlue {
//    background-color: #e5f0ff;
//    color: #006dff;
// }
// .btnBasic_theme_darkBlue {
//    background-color: #006dff;
//    color: #ffffff;
// }

// .btnBasic_theme_lightWhite {
//    background-color: #fff;
//    color: #3f4f65;
// }

// .btnBasic_theme_white {
//    background-color: inherit;
//    color: #ffffff;
// }

// .btnBasic_theme_lightGrey {
//    background-color: #5e6e7d;
//    color: #ffffff;
// }

// .btnBasic_theme_darkBlack {
//    background-color: #333333;
//    color: #ffffff;
//    border-radius: 4px;
//    font-size: 16px;
//    font-family: 'Noto Sans', sans-serif;
//    font-weight: 700;
// }

// .btnBasic_theme_whiteBordered {
//    background-color: transparent;
//    border: solid 1px #2c6f95;
//    color: #333333;
//    border-radius: 4px;
//    font-size: 12px;
//    font-family: 'Noto Sans', sans-serif;
//    font-weight: 700;
// }

// .btnBasic_size_medium {
//    height: 32px;
//    min-width: 100px;
//    padding-right: 20px;
//    padding-left: 20px;
//    font-size: 13px !important;
// }

// .btnBasic_size_large {
//    height: 40px;
//    padding-right: 30px;
//    padding-left: 30px;
// }
// .btnBasic_size_extraLarge {
//    height: 47px;
//    width: 132px;
//    padding-right: 30px;
//    padding-left: 30px;
//    font-size: 14px;
// }

// .btnBasic_size_extraLargeNarrow {
//    height: 40px;
//    width: 132px;
//    padding-right: 30px;
//    padding-left: 30px;
//    font-size: 14px;
// }

// .btnBasic_size_full {
//    width: 100%;
//    height: 48px;
//    padding-right: 20px;
//    padding-left: 20px;
// }

// .btnBasic_disable_true {
//    opacity: 0.65;
//    cursor: not-allowed;
// }

// .btnBasic:focus {
//    outline: none;
// }

// @media screen and (max-width: 720px) {
//    .btnBasic {
//       width: 100%;
//       height: 48px;
//    }
// }

.button__green {
   color: var(--primary_80);
   border: 1px solid var(--primary_80);
   background-color: inherit;
   font-family: "Roboto", serif;
   font-weight: 500;
   font-size: 16px;
   // padding-inline: 12px;
   white-space: nowrap;

   &:hover {
      background-color: var(--primary_5);
      transition: 0.3s ease;
   }

   &:focus {
      background-color: var(--primary_5);
      box-shadow: rgb(84, 147, 139) 0px 0px 4px;
   }


}
