.affiliate__checkout__editor {
   height: calc(100vh - 69px);
   transition: 0.3s all;
   background-color: white;
   position: relative;
   border-right: 1px solid #e7e9e9;
   width: 277px;
   position: absolute;
   padding: 20px;
   box-sizing: border-box;
   z-index: 0;
   overflow-y: auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   &__empty {
      display: flex;
      flex-direction: column;
      gap: 32px;
   }
   &__sections {
      display: flex;
      flex-direction: column;
      gap: 12px;
   }
   &__section {
      padding: 10px 12px;
      cursor: pointer;
   }
}
