.affiliate__checkout{
    width: 100%;
    position: relative;
    &__theme{
      margin-left: 277px;
      width: calc(100% - 277px);
      box-sizing: border-box;
      position: relative;
      transition: 600ms all ease;
      overflow: auto;
      height: calc(100vh - 55px);
      padding-top: 60px;
      padding-bottom: 60px;
      scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
      scrollbar-width: thin;
      background-color: #f4f7f7;
      &::-webkit-scrollbar-track {
         border-radius: 10px;
         background-color: transparent;
      }

      &::-webkit-scrollbar {
         width: 5px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         border-radius: 3px;
         box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
         background-color: rgb(226, 226, 226);
      }
    }
}

.TabletMode {
   transition: all 0.65s ease 0s;
   min-width: 487px;
   max-width: 487px;
   height: 80vh;
   margin: 0 auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   iframe {
      border: none;
      width: 100%;
      height: 100%;
   }
}

.PhoneMode {
   transition: all 0.65s ease 0s;
   min-width: 487px;
   max-width: 487px;
   height: 60vh;
   overflow: hidden;
   margin: 0 auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
   iframe {
      border: none;
      width: 100%;
      height: 100%;
   }
}
.frame-content{
   overflow-y: scroll;
   max-height: 100vh;
   overflow-x: hidden;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
}

.DesktopMod {
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
}

// .adminContainer {
//    padding: 0px;
//    overflow: hidden;
// }

.affiliate__checkout__theme__closed{
   margin-left: 0px;
   transition: 600ms all ease;
    width: 100%;
}

.affiliate__section__top__item{
   width: 100%;
}

.affiliate__edit__showed{
   padding: 0px !important; 
   overflow-y: hidden !important;
}

.editor{
   background-color: #f4f7f7;
}