.affiliate__sign{
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__remember{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    &__accept{
        display: flex;
        align-items: center;
        gap: 8px;

        &__link {
            cursor: pointer;
        }
    }
}