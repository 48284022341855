.sidebar {
   height: 100%;
  // min-height: 100vh;
   position: sticky;
   top: 0;
   background-color: #fff;
   overflow: auto;
   overflow-x: hidden;
   scrollbar-color: #E8F2F1 rgb(226, 226, 226);
   scrollbar-width: thin;
   border-right: 1px solid #E7E9E9;
   padding: 0px 16px;
   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px #E8F2F1;
      -webkit-box-shadow: inset 0 0 6px #E8F2F1;
      background-color: rgb(226, 226, 226);
   }

   &__menu {
      display: flex;
      height: calc(100% - 87px);
      box-sizing: border-box;
      flex-direction: column;
   }

   .settings__margin {
      margin-top: auto;
   }

   .logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
         max-width: 100px;
         width: 100%;
      }
   }

   .sidebar__menu {
      display: flex;
      height: calc(100% - 87px);
      box-sizing: border-box;
      flex-direction: column;
   }

}
.small_sidebar_w {
   width: 72px;
    display: none;
   .logo {
      padding: 32px 15px;
   }
}

.sidebar_w {
   width: 240px;
   box-sizing: border-box;
   .logo {
      padding: 32px 24px 24px 16px;
   }
}


@media screen and (max-width: 1280px) {
   .sidebar_w {
      width: 201px;
   }
   .sidebarLink__content {
      padding: 15px 17px 14px 20px;
  }
  .sidebarGroup__content {
   padding: 15px 17px 14px 17px;
  }

}

// @media screen and (max-height: 844px) {
//    .sidebar {
//       .settings__margin {
//          margin-top: 40px;

//       }
//    }
// }



// @media screen and (max-height: 806px) {
//    .sidebar {
//       .settings__margin {
//          margin-top: 20px;
//       }
//    }
// }

// @media screen and (max-height: 786px) {
//    .sidebar {
//       .settings__margin {
//          margin-top: 10px;
//       }
//    }
// }

// @media screen and (max-height: 776px) {
//    .sidebar {
//       .settings__margin {
//          margin-top: 0px;
//       }
//    }
// }




.cls-1 {
   fill: none;
 }

.cls-2 {
   clip-path: url(#clippath);
   }

.cls-3 {
   fill: url(#linear-gradient);
   fill-rule: evenodd;
   }

.cls-4 {
   fill: url(#linear-gradient-3);
   }

.cls-5 {
   fill: url(#linear-gradient-2);
   }
