.errorMessageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &.error_border {
        .inputNew,
        .SelectItem__button__medium,
        .course__integration__bottom,
        .plan__pricing__left__plans__integration__bottom,
        .textArea__input,
        .text_area_new,
        .community__post__create__editor,
        .inputLink,
        .textarea_component,
        .tag__input,
        .multiSelect-basic,
        .quill {
            border-color: var(--additional_error) !important;
        }
    }

    &_errorMessages {
        margin-bottom: 6px;
        display: flex;
        flex-direction: column;
        gap: 3px;

        .errorMessage {
            max-width: 100%;
            margin: 0;
            color: var(--additional_error);
        }
    }

}